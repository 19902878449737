import clsx from 'clsx';
import { PrimaryButton } from 'Components/Buttons';
import { Box, Heading } from 'grommet';
import { get } from 'lodash-es';
import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { connect, useDispatch } from 'react-redux';
import { buildURL } from 'Shared/fetch';
import useFetch from 'use-http';

import { emailRegex } from '@/helpers';
import { useIsMobileSize } from '@/hooks';
import { setUserData } from '@/redux/slices/user/actions';

import Accordion from '../Accordion';
import style from '../style.scss';

const EmailSection = ({ expand = true, userEmail }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(userEmail);
  const [hasError, setHasError] = useState();
  const isMobileSize = useIsMobileSize();
  const { post, error, data, loading } = useFetch(buildURL('/users/account/email'), {
    credentials: 'include',
    cachePolicy: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
  });

  useEffect(() => {
    const user = get(data, 'user');
    const message = get(data, 'error');

    if (error || message) {
      setHasError(message || 'Something Went Wrong, please try again!');
    } else if (user) {
      dispatch(setUserData(user));
      setHasError(false);
    }
  }, [error, data, dispatch]);

  const changeHandler = ({ target: { value } }) => {
    setEmail(value);
  };

  const updateHandler = async () => {
    if (!email.match(emailRegex)) {
      setHasError('Invalid email.');
      return;
    }

    await post({ email });
  };

  return (
    <Accordion
      expanded={expand}
      className={isMobileSize && style.mobileAccordion}
      header={<Heading level="4">Email</Heading>}
    >
      <Box>
        <Box
          border={{ color: '#DFDFDF', size: '1px', side: 'top' }}
          pad={{ horizontal: '50px', top: '30px', bottom: '40px' }}
        >
          {isMobileSize && (
            <div className={style.note}>We’ll send event reminders and your photos/clips to this email address.</div>
          )}
          <Box direction="row">
            <div className={style.label}>
              <span>Email</span>
              {!isMobileSize && (
                <div className={style.note}>We’ll send event reminders and your photos/clips to this email address.</div>
              )}
            </div>
            <div className={style.inputContainer}>
              <div className={style.inputWrapper}>
                <input
                  type="text"
                  name="email"
                  placeholder="email@example.com"
                  value={email}
                  onChange={changeHandler}
                  className={clsx({ [style.errorBorder]: hasError })}
                />
              </div>

              <div className={clsx(style.errorSection, { [style.active]: hasError })}>{hasError}</div>

              <Box margin={{ top: !isMobileSize ? '30px' : '6px' }} />

              <PrimaryButton
                className={style.updateButton}
                onClick={updateHandler}
                primary
                label={get(data, 'user.email') === email ? 'Updated ✔' : 'Update'}
                size="large"
                loading={loading}
              />
            </div>
          </Box>
        </Box>
      </Box>
    </Accordion>
  );
};

export default connect(({ user: { email } }) => ({ userEmail: email }))(EmailSection);
