import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { OutlineButton, OutlineButtonDanger, PrimaryButton } from 'Components/Buttons';
import { Box, Heading, Layer, Text } from 'grommet';
import { useState } from 'preact/hooks';
import { connect } from 'react-redux';
import { buildURL } from 'Shared/fetch';
import useFetch from 'use-http';

import { useIsMobileSize } from '../../../hooks';
import Accordion from '../Accordion';
import style from '../style.scss';

const DeleteAccountSection = ({ expand = true, subscription, role }) => {
  const [deleteError, setDeleteError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [successfullyDeleted, setSuccessfullyDeleted] = useState(false);

  const isMobileSize = useIsMobileSize();
  const { del, error, response, loading } = useFetch(buildURL('/users/account/delete'), {
    credentials: 'include',
    cachePolicy: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
  });

  const modalHandler = async () => {
    setShowModal(true);
  };
  const deleteHandler = async () => {
    await del();
    if (!error && !loading && response.ok) {
      setSuccessfullyDeleted(true);
      setTimeout(() => {
        setShowModal(false);
        // navigating to '/map' will automatically logout the user:
        window.location.href = `/map`;
      }, 3000);
    } else if (!loading) {
      setDeleteError(true);
    }
  };

  return (
    <>
      {showModal && (
        <Layer
          position="center"
          modal
          onClickOutside={() => {
            setShowModal(false);
            setDeleteError(false);
          }}
          onEsc={() => setShowModal(false)}
        >
          <Box align="center" width="290px" margin={{ vertical: '30px', horizontal: isMobileSize ? '22px' : '50px' }}>
            <button onClick={() => setShowModal(false)} type="button" className={style.close}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <Box pad={{ vertical: '20px', horizontal: 'medium' }}>
              <Heading level="5" margin="none">
                Confirm Account Removal
              </Heading>
              <Box
                alignSelf="center"
                width="50%"
                height="3px"
                background="var(--red)"
                margin={{ top: '5px' }}
                className={style.line}
              />
            </Box>
            <Text textAlign="start" margin={{ bottom: '20px' }}>
              Are you sure you want to delete your account?
              <br />
              <br />
              All your personal data will be permanently deleted from our servers.
              {subscription.active && role === 'user' && ' If you have any active memberships, they will also be cancelled.'}
            </Text>
            {deleteError && (
              <Text weight="bolder" textAlign="center" size="large" color="var(--red)" margin={{ bottom: '20px' }}>
                An error occured while deleting your account. Please try again or contact us at support@zoolife.tv if the
                problem persists.
              </Text>
            )}
            {successfullyDeleted && (
              <Text weight="bolder" textAlign="center" size="large" color="var(--primaryGreen)" margin={{ bottom: '20px' }}>
                Your account has been successfully deleted. Now you will be logged out... <br />
                <br />
                Thank you for your time with us.
              </Text>
            )}
            <Box direction="row" justify="between" margin={{ bottom: '10px', top: '10px' }} gap="10px">
              <OutlineButton
                size="medium"
                label="Close"
                onClick={() => {
                  setShowModal(false);
                  setDeleteError(false);
                }}
              />
              <OutlineButtonDanger
                size="medium"
                label="Delete"
                onClick={deleteHandler}
                loading={loading}
                disabled={successfullyDeleted}
              />
            </Box>
          </Box>
        </Layer>
      )}
      <Accordion
        expanded={expand}
        className={clsx(style.mobileAccordion, style.passowrd)}
        header={<Heading level="4">Delete My Account</Heading>}
      >
        <Box>
          <Box
            border={{ color: '#DFDFDF', size: '1px', side: 'top' }}
            pad={{ horizontal: '50px', top: '30px', bottom: '40px' }}
          >
            {isMobileSize && <div className={style.note}>Want to delete your account?</div>}
            <Box direction="row">
              <div className={style.label}>
                {!isMobileSize && <div className={style.note}>Want to delete your account?</div>}
              </div>
              <div className={style.inputContainer}>
                <Box margin={{ top: !isMobileSize ? '30px' : '6px' }} />
                <PrimaryButton className={style.updateButton} onClick={modalHandler} primary label="Delete" size="large" />
              </div>
            </Box>
          </Box>
        </Box>
      </Accordion>
    </>
  );
};

export default connect(({ user: { subscription, role } }) => ({
  subscription,
  role,
}))(DeleteAccountSection);
