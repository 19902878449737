import clsx from 'clsx';
import { hasPermission } from 'Components/Authorize';
import Header from 'Components/Header';
import { LayoutDetector } from 'Components/LayoutDetector';
import PageWrapper from 'Components/Main/PageWrapper';
import { Box, Grommet, Heading, Tab, Tabs } from 'grommet';
import { merge } from 'lodash-es';
import { route, useRouter } from 'preact-router';
import { Permission } from 'zl-shared';

import Plans from '@/routes/plans';

import grommetTheme from '../../grommetTheme';
import { useIsMobileSize } from '../../hooks';
import Profile from '../profile';
import DeleteAccountSection from './MyAccount/DeleteAccountSection';
import EmailSection from './MyAccount/EmailSection';
import MarketingOptInSection from './MyAccount/MarketingOptInSection';
import PasswordSection from './MyAccount/PasswordSection';
import style from './style.scss';

const tabsTheme = {
  tab: {
    active: { color: 'var(--secondaryGreen)' },
    hover: { color: 'var(--grey)' },
    color: 'var(--grey)',
    disabled: { color: 'var(--blueMediumDark)' },
    border: {
      color: 'transparent',
      active: { color: 'var(--secondaryGreen)' },
      hover: { color: 'var(--grey)' },
    },
  },
};

const tabs = ['me', 'info', 'subscription'];
const tabPicker = (activeTab) => {
  if (tabs.includes(activeTab.toLowerCase())) {
    return tabs.indexOf(activeTab);
  }

  // info is the default tab if active tab is not listed
  return 1;
};

const onTabChange = (index) => {
  route(`/account/${tabs[index]}`);
};

const AccountTabs = () => {
  const [route] = useRouter();
  const activeTab = route?.matches?.activeTab;
  const isMobileSize = useIsMobileSize();

  return (
    <Grommet theme={merge(tabsTheme, grommetTheme)} className={style.accountContainer}>
      <Box fill>
        <Box fill>
          <Tabs
            activeIndex={tabPicker(activeTab)}
            onActive={onTabChange}
            className={clsx(style.tabs, { [style.mobile]: isMobileSize })}
            flex
          >
            <Tab title="My Profile">
              <Box fill border={{ color: '#DFDFDF', size: '1px', side: 'top' }}>
                <Profile />
              </Box>
            </Tab>
            <Tab title="Account Info">
              <Box fill overflow="auto" border={{ color: '#DFDFDF', size: '1px', side: 'top' }} style={{ display: 'block' }}>
                <Box
                  width={{ max: 'min(885px, calc(100vw - 40px))' }}
                  margin="auto"
                  fill="horizontal"
                  pad={{ vertical: '20px', top: '20px', bottom: '40px' }}
                >
                  <Heading fill textAlign="center" level="2" margin={isMobileSize ? '0 10px 20px' : undefined}>
                    Account Information
                  </Heading>
                  <EmailSection />
                  <Box pad="20px" />
                  <PasswordSection />
                  <Box pad="20px" />
                  <MarketingOptInSection />
                  <Box pad="20px" />
                  <DeleteAccountSection />
                </Box>
              </Box>
            </Tab>
            {hasPermission(Permission.Subscription.Edit) && (
              <Tab title="Manage Membership">
                <Plans />
              </Tab>
            )}
          </Tabs>
        </Box>
      </Box>
    </Grommet>
  );
};

const AccountPage = () => (
  <LayoutDetector
    desktop={
      <>
        <Header />
        <PageWrapper>
          <AccountTabs />
        </PageWrapper>
      </>
    }
    mobile={<AccountTabs />}
  />
);

export default AccountPage;
