import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { PrimaryButton } from 'Components/Buttons';
import { Box, CheckBox, Heading } from 'grommet';
import { useEffect, useState } from 'preact/hooks';
import OneSignal from 'react-onesignal';
import { useDispatch } from 'react-redux';

import { useIsMobileLayout } from '@/components/LayoutDetector/hooks';
import { postUserMarketingOptInMutationFn } from '@/queries/users';
import { useSelector } from '@/redux/helper';
import { setUserData } from '@/redux/slices/user/actions';

import Accordion from '../Accordion';
import style from '../style.scss';

/** @type {() => Record<import('@/shared/pushNotifications/types').UserNotificationTag, boolean>} */
const getCurrentUserTags = () =>
  Object.fromEntries(Object.entries(OneSignal.User.getTags() || {}).map(([key, value]) => [key, value === '1']));

const MarketingOptInSection = ({ expand = true }) => {
  const dispatch = useDispatch();
  const isMobileSize = useIsMobileLayout();
  const {
    data,
    isLoading,
    isSuccess,
    error,
    mutate: updateMarketingOptIn,
  } = useMutation({
    mutationFn: postUserMarketingOptInMutationFn,
    onSuccess: (data) => {
      if (data?.user) {
        dispatch(setUserData(data.user));
      }
    },
  });
  const isOneSignalAvailable = typeof window !== 'undefined' && 'OneSignal' in window;

  const errorMessage = data?.user ? '' : data?.error || (error ? 'Something Went Wrong, please try again!' : '');

  const userMarketingOptIn = useSelector((state) => state.user.isMarketingOptIn ?? false);
  const [formData, setFormData] = useState(() => {
    const tags = getCurrentUserTags();
    return {
      isMarketingOptIn: userMarketingOptIn,
      ...tags,
    };
  });

  // get tags from onesignal after 1s again to let it update from the server
  useEffect(() => {
    const id = setTimeout(() => {
      const tags = getCurrentUserTags();
      setFormData((p) => ({ ...p, ...tags }));
    }, 1000);
    return () => clearTimeout(id);
  }, []);

  const updateHandler = () => {
    const data = {
      isMarketingOptIn: formData.isMarketingOptIn,
      notificationTags:
        isOneSignalAvailable && OneSignal.Notifications.permission
          ? {
              postReactions: formData.postReactions ? '1' : '0',
              animalActivityAlerts: formData.animalActivityAlerts ? '1' : '0',
              topHighlights: formData.topHighlights ? '1' : '0',
            }
          : undefined,
    };
    updateMarketingOptIn(data);
    if (data.notificationTags) {
      OneSignal.User.addTags(data.notificationTags);
    }
  };

  return (
    <Accordion
      expanded={expand}
      className={isMobileSize && style.mobileAccordion}
      header={<Heading level="4">Notification Preferences</Heading>}
    >
      <Box>
        <Box
          border={{ color: '#DFDFDF', size: '1px', side: 'top' }}
          pad={{ horizontal: '20px', top: '30px', bottom: '40px' }}
        >
          <Box direction="column" gap="medium">
            <Box direction={isMobileSize ? 'column' : 'row'} gap="medium">
              <h6 className="preflight preflight-h6 w-80 text-base text-grey-2">Marketing Emails</h6>
              <div className={clsx(style.inputContainer, 'flex-auto mobile:!w-full')}>
                <CheckBox
                  label="Sign me up for the community newsletter and special promotions"
                  checked={formData.isMarketingOptIn}
                  onChange={(event) => setFormData((p) => ({ ...p, isMarketingOptIn: event.target.checked }))}
                />
                <div className={clsx(style.errorSection, 'empty:hidden', !!errorMessage && style.active)}>
                  {errorMessage}
                </div>
              </div>
            </Box>
            <Box direction={isMobileSize ? 'column' : 'row'} gap="medium">
              <h6 className="preflight preflight-h6 w-80 text-base text-grey-2 mobile:mt-4">Notifications</h6>
              <div className={clsx(style.inputContainer, 'flex flex-auto flex-col gap-2 mobile:!w-full')}>
                {!isOneSignalAvailable || !OneSignal.Notifications.isPushSupported() ? (
                  <p className="preflight preflight-p">
                    Push notifications aren’t supported on this browser. Please use a different device or browser to manage
                    your preferences.
                  </p>
                ) : OneSignal.Notifications.permissionNative === 'denied' ? (
                  <p className="preflight preflight-p">
                    Enable push notifications in your browser settings to update preferences.
                  </p>
                ) : OneSignal.Notifications.permissionNative === 'default' ? (
                  <p className="preflight preflight-p">
                    <button
                      type="button"
                      className="preflight preflight-button font-bold text-primary-green hover:text-secondary-green"
                      onClick={() => OneSignal.Notifications.requestPermission()}
                    >
                      Enable notifications
                    </button>{' '}
                    in your browser to manage preferences.
                  </p>
                ) : (
                  <>
                    <CheckBox
                      name="animalActivityAlerts"
                      label="Sign me up for the animal activity alerts"
                      checked={formData.animalActivityAlerts}
                      onChange={(event) => setFormData((p) => ({ ...p, animalActivityAlerts: event.target.checked }))}
                    />
                    <CheckBox
                      name="topHighlights"
                      label="Sign me up for the top highlights"
                      checked={formData.topHighlights}
                      onChange={(event) => setFormData((p) => ({ ...p, topHighlights: event.target.checked }))}
                    />
                    <CheckBox
                      name="postReactions"
                      label="Sign me up for the post reactions and comments"
                      checked={formData.postReactions}
                      onChange={(event) => setFormData((p) => ({ ...p, postReactions: event.target.checked }))}
                    />
                  </>
                )}
              </div>
            </Box>
            <PrimaryButton
              className={style.updateButton}
              onClick={updateHandler}
              primary
              label={isSuccess ? 'Updated ✔' : 'Update'}
              size="large"
              loading={isLoading}
              style={{ alignSelf: 'flex-end' }}
            />
          </Box>
        </Box>
      </Box>
    </Accordion>
  );
};

export default MarketingOptInSection;
